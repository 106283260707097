<template>
  <div class="content-page">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-block card-stretch card-height blog blog-detail">
            <div class="card-header d-flex justify-content-center">
              <div class="header-title ">
                <h5 class="card-title text-success">隱私政策</h5>
              </div>
            </div>
            <div class="card-body">
              <div class="blog-description mt-3">
                <p>本隱私權條款的編制目的是在提供在乎其線上使用個人資訊的使用者更好的服務。個人資訊是特定資訊可以被用於辨識、聯絡或定位個人，或是辨識獨立個體的內容。 請仔細閱讀我們的隱私權政策以清楚了解我們如何在我們網站蒐集、使用、保護或處理您的個人資訊。</p>
                <h2><strong>我們會如何蒐集資訊？</strong></h2>
                <p>我們收集資訊是為了提供您更優質的服務，並用以判斷符合您需求的基本設定，當您在網站上購買或註冊時，我們可能會適當的要求您輸入您的姓名、電子郵件地址、信用卡資訊或其他資訊以協助您有更好的體驗。</p>
                <p>如果您未登入本平台時，我們會運用您使用的瀏覽器或裝置所連接的唯一識別碼儲存我們所收集的資訊。</p>
                <p>如果您登入本平台，我們會另外蒐集您註冊或使用本平台時所提供或儲存的相關資訊，並作為您的個人資料處理。</p>
                <h2><strong>我們什麼時候蒐集資訊？</strong></h2>
                <p>我們會在您嘗試註冊網站或app、登入網站、使用網站、購買商品、填寫表單或提出支援請求等情形時蒐集資訊。</p>
                <h2><strong>我們會蒐集哪些資訊？</strong></h2>
                <p>我們可能會蒐集您使用的應用程式、瀏覽器和裝置資訊，除了提供您更優質的服務外，更有機會確保您所使用的應用程式、瀏覽器或裝置是屬於您個人所有，以盡可能的保障您的帳號安全。</p>
                <p>我們可能會蒐集您使用本網站服務時進行的相關活動資訊，其中可能包含有您蒐尋的字詞、您觀看的影片、瀏覽次數、互動次數、交易活動、交流對象、分享對象、瀏覽紀錄、付款資訊、付款紀錄、電子郵件資訊及聯絡資訊等。</p>
                <h2><strong>我們會如何使用您的資訊？</strong></h2>
                <p>我們會以下列方式使用我們向您蒐集的資訊於您註冊時、購買時、登錄電子報時、回應調查時、市場溝通、瀏覽網頁或其他特定網站內容：</p>
                <ul>
                  <li>提供您個人化體驗以允許我們傳送您可能有興趣的內容或產品給您。</li>
                  <li>改進我們的網站以提供您更好的服務。</li>
                  <li>允許我們依照您的要求或回應提供更好的服務。</li>
                  <li>提供您更快速的交易方法。</li>
                  <li>請求您對產品或服務評分或留言。</li>
                  <li>在通訊後更進一步的追蹤（即時聊天、電子郵件或電話）</li>
                </ul>
                <h2><strong>我們如何保護您的資訊？</strong></h2>
                <p>我們的網站會定期掃描安全漏洞和已知漏洞，以便您盡可能安全地訪問我們的網站。</p>
                <p>您的個人資訊被包含在安全的網路內，而且僅有少數具有特定權限的人員可以讀取，而且前述人員保密義務。除此之外，所有您提供的敏感資訊及信用卡資訊都將被SSL加密保護。</p>
                <p>我們實施了多種的安全保密措施，當使用者輸入、傳送或存取訂單時，其資訊將被保持在安全的狀態。</p>
                <p>所有交易都是透過第三方交易供應商處理，並非儲存於我們的伺服器。</p>
                <h2><strong>我們是否使用Cookies？</strong></h2>
                <p>
                  是，Cookies是一個微小的檔案由網站或是服務提供者傳送到您的電腦硬碟中透過您的瀏覽器（若您同意）以使網站或服務提供者的系統可以辨別您的瀏覽器，或擷取或記憶您的特定資訊。例如我們使用Cookies以協助記憶或處理您購物車內的項目。Cookies同時作為協助我們了解您前一次或現在網站活動的使用偏好以讓我們改進我們的服務。我們也會使用Cookies以協助我們編譯關於網站的數據匯總包含網站流量及網站流向等以讓我們在未來提供更好的網站使用體驗及工具。
                </p>
                <p>關於Cookies的更多資訊請參閱本網站的Cookies條款。</p>
                <h2><strong>第三方揭露</strong></h2>
                <p>我們不會販賣、交易或以其他方式傳送您的個人資訊給任何外界第三方，除非我們事先通知用戶。但前述的說明並不包含網站主機夥伴或其他協助網站營運、執行或提供服務的夥伴，且他們必須遵守保密義務。我們可能會釋放在虛要符合法規、符合網站相關政策或保護其他人的權益地的情況下安全且適當地釋出資訊。</p>
                <p>然而，非個人資訊可能被提供給第三方作為行銷、廣告或其他用途。</p>
                <h2><strong>第三方鏈結</strong></h2>
                <p>我們可能會以我們單方面決定提供或包含第三方產品或服務於本網站，這些第三方網站可能有分開或獨立的隱私權政策。我們對於第三方網站的內容或活動並沒有任何責任或義務。儘管如此，我們力求保護我們網站的完整性，並歡迎任何有關這些網站的反饋意見。</p>
                <h2><strong>我們蒐集您的電子郵件為了：</strong></h2>
                <ul>
                  <li>傳送訊息、回應需求、回應問題或回應要求。</li>
                  <li>處理訂單、傳送資訊以及更新特定訂單。</li>
                  <li>傳送關於您產品或服務額外資訊給您。</li>
                  <li>在交易發生後以行銷目的做成電子郵件名單或持續傳送電子郵件給客戶。</li>
                </ul>
                <h2><strong>您可以：</strong></h2>
                <ul>
                  <li>在您的帳號頁面決定您的個人資料的隱私權設定。</li>
                  <li>您可以向本網站申請完整刪除您的帳戶資料，但您所有的帳戶資料都將會被刪除，包含您的消費紀錄及購買的內容，並且刪除是完全不可逆的。</li>
                  <li>您可以向本網站申請匯出您的帳戶資料，我們會提供您的帳號相關的資料表給您。</li>
                </ul>
                <p>本隱私權條款的編制目的是在提供在乎其線上使用個人資訊的使用者更好的服務。個人資訊是特定資訊可以被用於辨識、聯絡或定位個人，或是辨識獨立個體的內容。 請仔細閱讀我們的隱私權政策以清楚了解我們如何在我們網站蒐集、使用、保護或處理您的個人資訊。</p>
                <h2><strong>我們會如何蒐集資訊？</strong></h2>
                <p>我們收集資訊是為了提供您更優質的服務，並用以判斷符合您需求的基本設定，當您在網站上購買或註冊時，我們可能會適當的要求您輸入您的姓名、電子郵件地址、信用卡資訊或其他資訊以協助您有更好的體驗。</p>
                <p>如果您未登入本平台時，我們會運用您使用的瀏覽器或裝置所連接的唯一識別碼儲存我們所收集的資訊。</p>
                <p>如果您登入本平台，我們會另外蒐集您註冊或使用本平台時所提供或儲存的相關資訊，並作為您的個人資料處理。</p>
                <h2><strong>我們什麼時候蒐集資訊？</strong></h2>
                <p>我們會在您嘗試註冊網站或app、登入網站、使用網站、購買商品、填寫表單或提出支援請求等情形時蒐集資訊。</p>
                <h2><strong>我們會蒐集哪些資訊？</strong></h2>
                <p>我們可能會蒐集您使用的應用程式、瀏覽器和裝置資訊，除了提供您更優質的服務外，更有機會確保您所使用的應用程式、瀏覽器或裝置是屬於您個人所有，以盡可能的保障您的帳號安全。</p>
                <p>我們可能會蒐集您使用本網站服務時進行的相關活動資訊，其中可能包含有您蒐尋的字詞、您觀看的影片、瀏覽次數、互動次數、交易活動、交流對象、分享對象、瀏覽紀錄、付款資訊、付款紀錄、電子郵件資訊及聯絡資訊等。</p>
                <h2><strong>我們會如何使用您的資訊？</strong></h2>
                <p>我們會以下列方式使用我們向您蒐集的資訊於您註冊時、購買時、登錄電子報時、回應調查時、市場溝通、瀏覽網頁或其他特定網站內容：</p>
                <ul>
                  <li>提供您個人化體驗以允許我們傳送您可能有興趣的內容或產品給您。</li>
                  <li>改進我們的網站以提供您更好的服務。</li>
                  <li>允許我們依照您的要求或回應提供更好的服務。</li>
                  <li>提供您更快速的交易方法。</li>
                  <li>請求您對產品或服務評分或留言。</li>
                  <li>在通訊後更進一步的追蹤（即時聊天、電子郵件或電話）</li>
                </ul>
                <h2><strong>我們如何保護您的資訊？</strong></h2>
                <p>我們的網站會定期掃描安全漏洞和已知漏洞，以便您盡可能安全地訪問我們的網站。</p>
                <p>您的個人資訊被包含在安全的網路內，而且僅有少數具有特定權限的人員可以讀取，而且前述人員保密義務。除此之外，所有您提供的敏感資訊及信用卡資訊都將被SSL加密保護。</p>
                <p>我們實施了多種的安全保密措施，當使用者輸入、傳送或存取訂單時，其資訊將被保持在安全的狀態。</p>
                <p>所有交易都是透過第三方交易供應商處理，並非儲存於我們的伺服器。</p>
                <h2><strong>我們是否使用Cookies？</strong></h2>
                <p>
                  是，Cookies是一個微小的檔案由網站或是服務提供者傳送到您的電腦硬碟中透過您的瀏覽器（若您同意）以使網站或服務提供者的系統可以辨別您的瀏覽器，或擷取或記憶您的特定資訊。例如我們使用Cookies以協助記憶或處理您購物車內的項目。Cookies同時作為協助我們了解您前一次或現在網站活動的使用偏好以讓我們改進我們的服務。我們也會使用Cookies以協助我們編譯關於網站的數據匯總包含網站流量及網站流向等以讓我們在未來提供更好的網站使用體驗及工具。
                </p>
                <p>關於Cookies的更多資訊請參閱本網站的Cookies條款。</p>
                <h2><strong>第三方揭露</strong></h2>
                <p>我們不會販賣、交易或以其他方式傳送您的個人資訊給任何外界第三方，除非我們事先通知用戶。但前述的說明並不包含網站主機夥伴或其他協助網站營運、執行或提供服務的夥伴，且他們必須遵守保密義務。我們可能會釋放在虛要符合法規、符合網站相關政策或保護其他人的權益地的情況下安全且適當地釋出資訊。</p>
                <p>然而，非個人資訊可能被提供給第三方作為行銷、廣告或其他用途。</p>
                <h2><strong>第三方鏈結</strong></h2>
                <p>我們可能會以我們單方面決定提供或包含第三方產品或服務於本網站，這些第三方網站可能有分開或獨立的隱私權政策。我們對於第三方網站的內容或活動並沒有任何責任或義務。儘管如此，我們力求保護我們網站的完整性，並歡迎任何有關這些網站的反饋意見。</p>
                <h2><strong>我們蒐集您的電子郵件為了：</strong></h2>
                <ul>
                  <li>傳送訊息、回應需求、回應問題或回應要求。</li>
                  <li>處理訂單、傳送資訊以及更新特定訂單。</li>
                  <li>傳送關於您產品或服務額外資訊給您。</li>
                  <li>在交易發生後以行銷目的做成電子郵件名單或持續傳送電子郵件給客戶。</li>
                </ul>
                <h2><strong>您可以：</strong></h2>
                <ul>
                  <li>在您的帳號頁面決定您的個人資料的隱私權設定。</li>
                  <li>您可以向本網站申請完整刪除您的帳戶資料，但您所有的帳戶資料都將會被刪除，包含您的消費紀錄及購買的內容，並且刪除是完全不可逆的。</li>
                  <li>您可以向本網站申請匯出您的帳戶資料，我們會提供您的帳號相關的資料表給您。</li>
                </ul>
                <h1><strong>Cookies 政策</strong></h1>
                <p>SEEWE使用cookies在www.seewe.vip（以下簡稱服務）上，您同意於使用服務時使用cookies。</p>
                <p>SEEWE的Cookies政策將會說明「什麼是Cookies」、「我們如何使用Cookies」、「SEEWE的第三方夥伴如何使用Cookies於服務上」以及「您對Cookies的選擇以及更多資訊」。</p>
                <h2><strong>什麼是Cookies？</strong></h2>
                <p>Cookies是由您的瀏覽器傳送到本網站的一段文字片段，一個Cookies檔案會儲存在您的瀏覽器內並且允許本服務或第三方辨識您，或使您的下一次造訪網站更容易，或使本服務更好用。</p>
                <p>Cookies 可以是長期的或是暫存的。</p>
                <h2><strong>SEEWE如何使用Cookies？</strong></h2>
                <p>當您使用或存取服務時，我們可能放置數個Cookies在您的瀏覽器中。</p>
                <p>我們將會使用Cookies於下列目的中：啟用特定服務功能、提供系統分析、儲存您的喜好設定、啟用廣告播送（包含您的喜好廣告設定</p>
                <p>我們於服務中使用長期或暫存Cookies，而且我們使用不同的形式使用Cookies以運行服務。</p>
                <p>必要的Cookies－我們可能會使用必要的Cookies以驗證用戶身份並防止欺詐性使用用戶帳戶。</p>
                <h2><strong>第三方Cookies</strong></h2>
                <p>除了我們自行使用Cookies之外，我們可能也會使用各種不同的第三方Cookies以回報您使用服務的統計數據、廣告或服務等等。</p>
                <h2><strong>您對於Cookies的選擇</strong></h2>
                <p>若您想要刪除Cookies或操作您的瀏覽器以拒絕Cookies，請參閱瀏覽器的協助頁面。</p>
                <p>請注意，若您刪除Cookies或拒絕接受Cookies，您可能將無法使用我們提供的完整服務，您可能無法儲存您的喜好設定，也可能某些頁面無法完整顯示。</p>
                <p>&nbsp;</p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Privacy'
}
</script>

